//
//	Wizard
//

$wizard-button-size: 2rem;

.wizard {
	padding-top: $spacer;
}

/* Add this CSS in your stylesheet or component */
/* Add this CSS in your stylesheet or component */

// .square-position {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	height: 120px;
// 	border: 1px solid #ddd;
// 	/* Add your preferred border color */
// 	position: relative;
// }

// .position-option {
// 	position: absolute;
// 	width: 50%;
// 	height: 50%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	cursor: pointer;
// }

// /* Adjust the colors as needed */
// .position-option:hover {
// 	background-color: #f0f0f0;
// }

// .position-option input {
// 	position: absolute;
// 	opacity: 0;
// 	cursor: pointer;
// }

// /* Style the label when the corresponding input is checked */
// .position-option input:checked+.position-option {
// 	background-color: #3498db;
// 	/* Add your preferred color */
// 	color: #fff;
// 	/* Add your preferred text color */
// }
.customizedbtn {
	background-color: #28a745 !important;
	border: none !important;
	color: white !important;
}

.customizedbtn:hover {
	background-color: #aeffc0 !important;
	color: #28a745 !important;
	border: none !important;
}

.IconGeneratedLinkDIV {
	position: absolute;
	width: 10vw;
	height: 10vh;
	border: 1px solid red;
}

.wizard-progress {
	margin: ($wizard-button-size * 0.5) ($wizard-button-size * 0.5) (($wizard-button-size * 0.5) + $spacer);

	>.progress {
		height: 1px;
	}

	.wizard-progress-btn {
		width: $wizard-button-size;
		height: $wizard-button-size;
	}
}